import http from '@/utils/services/http'

export const list = (limit, page, filtros) => {
    let url = `/api/negocios?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listByFunil = (funil, limit, page, filtros) => {
    let url = `/api/funils/${funil}/negocios?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id = null) => {
    let url
    if (id) {
        url = `/api/negocios/${id}`
    } else {
        url = `/api/negocios/default`
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newNegocio = (data) => {
    let url = `/api/negocios`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateNegocio = (id, data) => {
    let url = `/api/negocios/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const updateFunilEtapaFromNegocio = (negocioid, funilEtapaId) => {
    let url = `/api/negocios/${negocioid}/funil-etapa/${funilEtapaId}`
    return http.patch(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

  export const updatePessoaNegocio = (negocio, person) => {
      let url = `/api/negocios/${negocio.id}/pessoaRelacionada`
      return http.post(url, person)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const updatePartesNegocio = (negocio) => {
      let url = `/api/negocios/${negocio.id}/partes`
      return http.post(url, negocio)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const updateContatoNegocio = (negocio) => {
      let url = `/api/negocios/${negocio.id}/contatos`
      return http.post(url, negocio)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const uploadArquivoNegocio = (id, data) => {
      let url = `/api/negocios/${id}/arquivos`
      return http.post(url, data, {
          'timeout': 300000
          // headers: {'Content-Type': 'multipart/form-data'}
      })
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const downloadArquivoNegocio = (negocio, arquivo, inline = false) => {
      let url = `/api/negocios/${negocio.id}/arquivos/${arquivo}/download`
      if (inline) {
          url = url + '?inline=true'
      }
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const deleteArquivo = (negocio, arquivoId) => {
      let url = `/api/negocios/${negocio.id}/arquivos/${arquivoId}`
      return http.delete(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const criarNota = (negocio, data) => {
      let url = `/api/negocios/${negocio.id}/nota`
      return http.post(url, data)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const showNota = (negocio, notaId) => {
      let url = `/api/negocios/${negocio.id}/notas/${notaId}`
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const historico = (negocio, tipo = null) => {
      let url = `/api/negocios/${negocio.id}/historico`
      if (tipo) {
          url = url + '?tipo=' + tipo
      }
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const showNotaLogByReferId = (negocio, referId) => {
      let url = `/api/negocios/${negocio.id}/notas/show-by-refer/${referId}`
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const listMovimentacoes = (limit, page, filtros) => {
      let url = `/api/negocios/movimentacoes?page=${page}&limit=${limit}${filtros}`
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const showMovimentacao = (id) => {
      let url = `/api/negocios/movimentacoes/${id}`
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const deleteNota = (negocio, notaId) => {
      let url = `/api/negocios/${negocio.id}/notas/${notaId}`
      return http.delete(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  
  export const listTiposParte = (limit, page, filtros) => {
      let url = `/api/negocios/tipos-parte?page=${page}&limit=${limit}${filtros}`
      return http.get(url)
          .then(response => {
              return Promise.resolve(response)
          })
          .catch(({response}) => {
              return Promise.reject(response)
          })
  }
  export const ganharPerder = (negocio, data) => {
    let url = `/api/negocios/${negocio.id}/ganhar`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
